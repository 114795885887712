@import "~@/styles/variables";


















.show-more-link {
  padding: #{$list-group-item-padding-y $list-group-item-padding-x};

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .link-arrow {
    margin-top: -3px;
  }
}
