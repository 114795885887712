@import "~@/styles/variables";

















































.radio-buttons-option {
  border-radius: 0;
  border: $custom-select-border-width solid $custom-select-border-color;
  color: $custom-select-color;
  letter-spacing: normal;

  &:hover:not(.disabled) {
    background: $pp-blue-highlight;
    color: $custom-select-color;
    z-index: 1;
    cursor: pointer;
  }

  &.active {
    color: $pp-primary-blue !important;
    border: $btn-border-width solid $pp-primary-blue !important;
    background: transparent !important;
    z-index: 3;
  }

  &.focus {
    z-index: 2;
  }

  &.disabled {
    color: $custom-select-color;
    border-color: $custom-select-border-color;
  }
}

::v-deep .radio-buttons-icon {
  display: block;
  height: 40px;
}
