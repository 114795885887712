@import "~@/styles/variables";




















































































































































































































.current-balance {
  font-size: $f-size-md;
}
