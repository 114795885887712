@import "~@/styles/variables";























































































































































































































































































































































































.border-none {
  border: none;
}
